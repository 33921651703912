@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap");

* {
  font-family: "IBM Plex Serif", serif;
}

#container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: var(--100dvh);

  font-size: min(2.5vmin, 1rem);
}

#container button {
  background-color: #0f5253;
  color: white;
  font-size: 1.5em;
  border-radius: 0.5em;
  font-weight: bold;
  border: none;
  min-width: 7em;
  padding: 0.5em;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

#boxes {
  padding: 2em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputWrapper + .inputWrapper .box {
  margin-left: 0.5em;
}

.inputWrapper:nth-child(4) {
  margin-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inputWrapper:nth-child(4)::before {
  content: "-";
  font-size: 2em;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 0.5em;
}

.box {
  min-width: 2em;
  max-height: 3em;
  line-height: 3em;
  background-color: #f0f0f0;
  border-radius: 0.5em;
  font-size: 2em;
  padding: 2px 1px;
}

h3 {
  white-space: nowrap;
}

/* button {
  background-color: #0f5253;
  color: white;
  font-size: 1.5em;
  border-radius: 0.5em;
  font-weight: bold;
  border: none;
  min-width: 7em;
  padding: 0.5em;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
} */
