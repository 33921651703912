@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap");

.leaflet-container {
  width: 100%;
  height: var(--100dvh);
  z-index: 1;
  position: relative;
}

canvas.leaflet-zoom-animated {
  opacity: 0.725 !important;
}

.popup {
  font-family: "IBM Plex Serif", serif;
  font-size: 1rem;
}

.popup span {
  font-weight: bold;
}

.popup .attribute {
  margin: 0rem;
  margin-bottom: 0.4rem;
}
