.error-box {
  width: 100%;
  height: 100%;
  min-height: var(--100dvh);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-text {
  font-size: 2.5em;
  font-weight: 600;
  text-align: center;

  padding-bottom: 0.5em;
}

.error-message {
  font-size: 1em;
  font-weight: 100;
}

.home-button {
  font: inherit;
  color: rgb(12, 48, 167);
  text-decoration-line: underline;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;

  padding: 0;
}
