@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap");

.host-page {
  font-family: "IBM Plex Serif", serif;
}

.host-page {
  position: relative;
  width: 100vw;
  width: 100dvw;
  height: var(--100dvh);

  isolation: isolate;
}

.host-page header {
  position: absolute;
  inset: 0 0 auto 0;
  padding: 1em;
  background-color: transparent;
  text-align: center;
  pointer-events: none;
}

.host-page header #logo {
  position: fixed;
  font-size: 1em;
  font-weight: bold;
  inset: 1rem auto auto 1rem;
  pointer-events: visible;
}

.host-page header h1 {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: fit-content;
  align-items: center;
  margin: auto;
  column-gap: 1em;
}

.host-page header h1 .session-id-label {
  grid-column: 1/ -1;
  font-size: 1.5rem;
  text-shadow: 
    -0.5px -0.5px 0 #fff,
    0.5px -0.5px 0 #fff,
    -0.5px 0.5px 0 #fff,
    0.5px 0.5px 0 #fff;
}

.host-page header h1 div .session-id {
  font-size: 3.5rem;
  text-shadow: 
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.host-page header h1 .row {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.host-page header .qr-wrapper {
  pointer-events: visible;
}

.host-page header svg {
  width: 1.25em;
  height: 1.25em;
  cursor: zoom-in;
}

.host-page header .qr-wrapper.large {
  position: fixed;
  inset: 0;
  isolation: isolate;
  z-index: 1000;
  cursor: zoom-out;
}

.host-page header .qr-wrapper.large svg {
  position: absolute;
  inset: 0;
  height: 70vmin;
  width: 70vmin;
  margin: auto;
  cursor: zoom-out;
}

.host-page dialog[open] .grid {
  display: grid;
  gap: 1em;
}

.host-page dialog .modal-text {
  grid-column: span 2;
  text-align: center;
}

.modal-text {
  max-width: 20em;
  margin: auto;
  font-weight: bold;
}

.host-page .menu-button {
  display: grid;
  place-items: center;
  pointer-events: visible;

  background: transparent;
  color: black;
  border: 1px solid black;
  border-radius: 100vmax;
  width: 1.5em;
  height: 1.5em;
  font-size: 2.5em;
  line-height: 1;
  padding: 0;
  margin-right: 1em;

  position: absolute;
  inset: 1rem 1rem auto auto;
}

.host-page .leaflet-container {
  z-index: -1;
}

.host-page dialog[open] .grid button#confirm-btn {
  background-color: #0f5253;
}

.host-page dialog[open] .grid button#cancel-btn {
  background-color: #afafaf;
}

.host-page dialog[open] .grid button {
  color: white;
  font-size: 1.5em;
  border-radius: 0.5em;
  font-weight: bold;
  border: none;
  min-width: 7em;
  padding: 0.5em;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}