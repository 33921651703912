@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap");

.map-page {
  font-family: "IBM Plex Serif", serif;
}

.map-page {
  position: relative;
  width: 100vw;
  width: 100dvw;
  height: var(--100dvh);
}

.map-page .controls {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1em;
  pointer-events: none;

  --100-container-width: calc(100vw - 4rem);
  --1-container-width: calc(var(--100-container-width) / 100);
  font-size: min(1rem, calc(3.2 * var(--1-container-width)));
  font-weight: bold;
  position: absolute;
  inset: 0 0 auto 0;
  padding: 1em 4rem 1em 1em;
  background-color: transparent;
}

.map-page .controls,
.map-page .session-id {
  z-index: 10;
}

.map-page .controls h1,
.map-page .controls .menu-button {
  margin: 0;
}

.map-page .controls h1 {
  font-size: 2.5em;
  margin-right: auto;
}

.map-page .controls #logo-header {
  pointer-events: visible;
}

.map-page .controls dialog[open] {
  display: block;
  pointer-events: visible;
}

.map-page .controls dialog[open] .grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  gap: 1em;
}

.map-page .menu-button {
  display: grid;
  place-items: center;
  pointer-events: visible;

  background: transparent;
  color: black;
  border: 1px solid black;
  border-radius: 100vmax;
  width: 1.5em;
  height: 1.5em;
  font-size: 2.5em;
  line-height: 1;
  padding: 0;
}

.map-page .menu-button *,
.map-page .menu-button *:before {
  vertical-align: bottom;
}

.map-page .menu-button .loading-ring {
  font-size: 0.75em;
}

.map-page .confirm-modal[open] .grid,
.map-page .menu-modal .grid {
  display: grid;
  gap: 1em;
}

.map-page .confirm-modal .modal-text {
  grid-column: span 2;
  text-align: center;
}

.modal-text {
  max-width: 20em;
  margin: auto;
  font-weight: bold;
}

.map-page .session-id {
  position: absolute;
  inset: auto auto 1em 1em;
  font-weight: bold;
  backdrop-filter: blur(2em);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  padding: 0.2em;
}

.map-page .controls dialog[open] .grid button {
  color: white;
  font-size: 1.5em;
  border-radius: 0.5em;
  font-weight: bold;
  border: none;
  min-width: 7em;
  padding: 0.5em;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

button.action-btns {
  background-color: #0f5253;
}

button#logout-btn {
  background-color: #0f5253;
}

button.close-btn {
  background-color: #afafaf;
}

button.confirm-btn {
  background-color: #0f5253;
}
