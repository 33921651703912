.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: clamp(0.5rem, 2vmin, 1rem);
}

.loader .loading-ring {
  font-size: 4em;
  padding-bottom: 0.4em;
}

.loading-ring {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
}
.loading-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 1em;
  height: 1em;
  border: 0.125em solid #000;
  border-radius: 50%;
  animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.loading-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-message {
  font-size: 2.5em;
  font-weight: 600;

  padding-bottom: 0.5em;

  text-align: center;
}

.loading-message:after {
  display: inline-block;
  content: "";
  min-width: 1.5ch;
  min-height: 1em;
  margin-right: -1.5ch;
  animation: loading-dots 2s linear infinite;

  text-align: left;
}

@keyframes loading-dots {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
}

.loading-warning {
  font-size: 1em;
  font-weight: 100;
}
