@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap");

* {
  font-family: "IBM Plex Serif", serif;
}

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: var(--100dvh);
}

.home-container button {
  color: white;
  font-size: 1.5em;
  border-radius: 0.5em;
  font-weight: bold;
  border: none;
  width: 12em;
  padding: .75em;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.home-container .host-menu-modal[open] .grid,
.home-container .host-menu-modal .grid {
  display: grid;
  gap: 1em;
}

.home-container .host-menu-modal {
  padding: 1.5em;
}

.home-container .host-menu-modal button {
  max-width: 15em;
  width: 100%;
  padding: 0.75em;
}

.home-container .host-menu-modal .modal-text {
  grid-column: span 2;
  text-align: center;
}

h1 {
  font-size: 2.75rem;
  font-weight: bolder;
}

.btn {
  border-radius: 15px;
  color: white;
  border: none;
  width: 12rem;
  padding: 1rem;
  margin: 0.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

#primary {
  background-color: #55a199;
}

#secondary {
  background-color: #0f5253;
}

#close-btn {
  background-color: #afafaf;
}

#beg {
  color: #55a199;
}

#goback {
  font-size: 12px;
  color: black;
  margin-top: 1rem;
}

#logo {
  text-decoration: none;
  color: inherit;
}

#session-id {
  color: black;
}
